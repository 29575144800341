'use client';

import { tokens } from '@/styles/tokens';
import { buildPath } from '@/utils/url';
import { Box, Checkbox, FormControl, FormLabel, Text } from '@medsimples/design-system/src/chakra';
import { Button, Input } from '@medsimples/design-system/src/components';
import Email from '@public/icons/email/email';
import Phone from '@public/icons/phone/phone';
import { capitalize } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';
import { FormEvent, Suspense, useState } from 'react';
import InputMask from 'react-input-mask';
import { doctorOnboardingAPI, getErrorMessage } from '../../../api';
import BackButton from '../../../components/BackButton';
import { useToasts } from '@medsimples/design-system';
import { SiginMethodType, SigninMethodLabel } from '../../../utils/constants';
import { PHONE_REGEX } from '../../../utils/regex';

function LoginPage() {
  const { errorToast } = useToasts();
  const searchParams = useSearchParams();
  const method = searchParams.get('method');
  const router = useRouter();
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [remindMe, setRemindMe] = useState(true);

  async function login() {
    setLoading(true);
    // TODO: fix siginUser route so it requires 'type' and 'value' only
    const payload = method === 'phone' ? {
      type: SiginMethodType[method],
      phone: value.replace(PHONE_REGEX, ''),
    } : {
      type: SiginMethodType[method],
      email: value.trim(),
    };

    await doctorOnboardingAPI().user
      .siginUser(payload)
      .then((res) => {
        if (res.error?.code) {
          errorToast(getErrorMessage(res.error.code));
          return;
        }
        if (!res.success) throw 'Unexpected Error';
        router.push(buildPath('/otp_verify', { value, method }));
        router.refresh();
      })
      .catch((err: Error) => {
        console.error(err);
        errorToast('Erro inesperado ao enviar código de validação', 'Erro');
        setLoading(false);
      });
  }

  async function onFormSubmit(e: FormEvent) {
    e.preventDefault();
    const form: any = e.target;

    if (!form.checkValidity()) {
      errorToast('Atenção', 'Verifique se todos os campos foram preenchidos corretamente');
      return;
    }
    await login();
  }

  return (
    <>
      <Box marginBottom={12}>
        <Text textAlign="center" fontSize="xl">
            Insira seu {SigninMethodLabel[method]} abaixo
        </Text>
      </Box>

      <form style={{ display: 'contents' }} onSubmit={onFormSubmit} noValidate={true}>

        <Box marginBottom={12} w="100%">
          <FormControl isRequired>
            <FormLabel>
              <Text fontSize="0.8em" color={tokens.text.secondary.color} fontWeight="bold" display="inline-block" >
                {capitalize(SigninMethodLabel[method])}
              </Text>
            </FormLabel>
            {method === 'phone' ? (
              <Input
                as={InputMask}
                leftElement={
                  <Phone color={tokens.text.secondary.color} width={17} height={17} />
                }
                mask="+55 (99) ?99999999"
                maskChar={null}
                formatChars={{
                  '9': '[0-9]',
                  '?': '[0-9]',
                }}
                placeholder="+55 (00) 0000-0000"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                marginBottom={2}
              />
            ) : (
              <Input
                leftElement={
                  <Email color={tokens.text.secondary.color} width={17} height={17} />
                }
                placeholder="nome@email.com"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                marginBottom={2}
              />)
            }
          </FormControl>
          <Checkbox
            size="sm"
            onChange={(e) => setRemindMe(e.target.checked)}
            isChecked={remindMe}
          >
              Lembrar de mim
          </Checkbox>
        </Box>

        <Box w="100%">
          <Button
            data-testid="button"
            isLoading={isLoading}
            type="submit"
          >
              Continuar
          </Button>
        </Box>
        <BackButton router={router} marginTop={5}></BackButton>
      </form>
    </>
  );
}

export default function LoginPageSuspense() {
  return (
    <Suspense>
      <LoginPage />
    </Suspense>
  )
}